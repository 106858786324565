import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["sv-tag", { 'sv-tag-border': _ctx.border > 0 || _ctx.borderRadius > 0 }]),
    style: _normalizeStyle({
    'height': _ctx.height + 'px',
    'lineHeight': _ctx.height + 'px',
    'color': _ctx.txtColor ? _ctx.txtColor : '',
    'font-size': _ctx.fontSize + 'px',
    'border': _ctx.border + 'px solid ' + _ctx.color,
    'border-radius': _ctx.borderRadius ? _ctx.borderRadius + 'px' : _ctx.border ? _ctx.height / 2 + 'px' : 'unset',
    'background': _ctx.background,
  })
  }, [
    (_ctx.type.indexOf('dotted') > -1)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "sv-tag-label",
          style: _normalizeStyle({ background: _ctx.color })
        }, null, 4))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("span", null, _toDisplayString(_ctx.txt), 1)
    ], true)
  ], 6))
}