<template>
  <div class="sv-search-box" id="search-bar">
    <div class="search-title">Search Options <div><SvFont class="iconfont icon-sv-icancellation searchOptionsDeleteIcon" size="16" color="#536372" @click="changeIsShrink"></SvFont></div></div>
    <!--普通搜索-->
 


    <el-scrollbar class="sv-search-list">
      <template v-if="searchData.searchInfo && searchData.searchInfo.length > 0">
        <template v-for="(item, index) in searchData.searchInfo" :key="index">
             <div class="sv-search-item" >
              <div class="sv-search-title">
                <span>{{item.title}}</span>
              </div>
              <template v-if="item.type == 'input'">
                <div class="sv-input-Item">
                  <el-input 
                    v-model="item.keyword"  
                    type="text"  
                    maxlength="255"
                    :placeholder="'Please input ' + item.title.toLowerCase()"  
                    clearable
                    :oninput="item.keyword=item.keyword.replace(/ {2,}/g, ' ')"
                    @blur="item.keyword=item.keyword.trim()"
                     />
                </div>
              </template>
              <template v-if="item.type == 'select-multiple'">
                <div class="sv-input-Item">
                <el-select
                  v-model="item.keyword"
                  multiple
                  :placeholder="'Please select ' + item.title.toLowerCase()"
                  style="width: 288px"
                  class="sv-searchbar-multiple"
                >
                  <el-option
                    v-for="item2 in item.selectData"
                    :key="item2[item.val]"
                    :label="item2[item.text]"
                    :value="item2[item.val]"
                  />
                </el-select>
              </div>
              </template>
              <template v-if="item.type == 'select'">
                <div class="sv-input-Item">
                <el-select
                  v-model="item.keyword"
                  :placeholder="'Please select ' + item.title.toLowerCase()"
                  style="width: 288px"
                  class="sv-searchbar-multiple"
                >
                  <el-option
                    v-for="item2 in item.selectData"
                    :key="item2[item.val]"
                    :label="item2[item.text]"
                    :value="item2[item.val]"
                  />
                </el-select>
              </div>
              </template>
              <template v-else-if="item.type == 'date-time-group'">
                <div class="sv-date-group">
                  <el-date-picker
                    v-model="item.content[0].keyword"
                    type="datetime"
                    placeholder="Start Time"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    time-format="HH:mm"
                    style="width: 288px;"
                    :teleported="true"
                  />
                  <div class="select-time-to" style="width: 288px;">to</div>
                  <el-date-picker
                    v-model="item.content[1].keyword"
                    type="datetime"
                    placeholder="End Time"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    time-format="HH:mm"
                    style="width: 288px;"
                    :teleported="true"
                  />
                </div>
              </template>
              <template v-else-if="item.type == 'select-input'">
                 <div class="select-input sv-flex-r-start-center" style="width: 288px;margin-left: 16px;">
                    <el-select
                    v-model="item.content[0].keyword"
                    :placeholder="'Please select ' + item.content[0].filed.toLowerCase()"
                    style="width: 50%"
                    v-if="item.content[0].filed == 'areaCodes'"
                  >
                    <el-option
                      v-for="item2 in phoneCodeSelect"
                      :key="item2.id"
                      :label="item2[item.text] + '(' + item2[item.val] +')'"
                      :value="item2[item.val]"
                    />
                  </el-select>
                  <el-input class="select-input-input" style="width: 50%;" v-model="item.content[1].keyword"    :placeholder="'Please Input ' + item.content[1].filed"></el-input>
                 </div>
              </template>
              
              <template v-if="item.type == 'cascader'">
                <div class="sv-input-Item">
                  <el-cascader clearable v-model="item.keyword" :placeholder="'Please select' + item.title.toLowerCase() " :props="item.props" :options="item.options"/>
                </div>
              </template>
             </div>
        </template>
      </template>
      <template v-else></template>
    </el-scrollbar>

    <div class="search-footer-btn sv-flex-r-between-center">
      <sv-button default line grey right="16" class="resetBtn" @click="reset(searchData)">
        <span>Clear</span>
      </sv-button>
      <sv-button default class="searchBtn" @click="search(searchData)">
        <SvFont classType="iconfont icon-sv-search" size="22" color="#FFFFFF"></SvFont>
        <!-- <SvFont classType="iconfont icon-a-zu93020" color="#FFFFFF" size="40" ></SvFont> -->
        <span style="display: inline-block;margin-left: 8px;">Search</span>
      </sv-button>
    </div>
  </div>
</template>

<script src="./sv-searchBar"></script> 
<style scoped src="./sv-searchBar.css">
</style>