import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useMenuStore } from '@/stores/menu';
import adminAuth from '@/utils/adminAuth';
import dataDir from '@/utils/data';

/***
 * 所有主应用中的路由的字符串前缀集合.
 * 1. 该值为不需要做路由正误判断和纠正的主应用路由字符串(路由回退时，由于主应用和子应用有两个vue-router，可能导致回退完整路由错误，所以添加纠正的逻辑，需要用到该值);
 * 2. 主应用的路由不能和子应用的路由重名 (可能导致在回退到子应用对应的重名路由时，子应用的vue-router使地址栏路由错误，页面没有渲染成子应用路由视图而错误地渲染成主应用路由视图);
 */
const mainAppRouteList = Object.values(dataDir.mainAppRoutes);
// 主应用路由名(会用于给主应用路由跳转去重和子应用避免错误地处理主应用容器路由跳转，避免同样的路由连续重复跳转多次导致的渲染卡顿)
const MICRO_APP_NAME = 'Micro App';

/***
 * 路由列表
 * 自定义route meta属性: 
 * @prop {boolean} [route.meta.isHalfFullScreenChild] 页面是否是非全屏子页面，可把一级路由设置为子页面样式.
 * @prop {boolean} [route.meta.isFullScreenChild] 页面是否是全屏子页面.
 * @prop {boolean} [route.meta.transparentBg] 页面是否透明背景.
 */
let routes: Array<RouteRecordRaw> = [
  {
    path: dataDir.mainAppRoutes.LOGIN,
    name: dataDir.mainAppRouteNames.LOGIN,
    component: () => import('../views/login/login.vue')
  },
  // 主应用及子应用容器
  {
    path: dataDir.HOME_ROUTE,
    name: dataDir.mainAppRouteNames.APP,
    component: () => import('../views/home/home.vue'),
    children: [
      {
        path: process.env.NODE_ENV === 'development' ? dataDir.HOME_ROUTE + '/:microApp' : dataDir.HOME_ROUTE + '/:microApp',
        name: MICRO_APP_NAME,
        component: () => import('../views/microApp/microApp.vue'),
        // 所有主应用的子页面也放这里(此处为层级为2的路由，顶部面包屑有路由回退按钮)
        children: [
          {
            path: dataDir.mainAppRoutes.ALL_MESSAGES,
            name: dataDir.mainAppRouteNames.ALL_MESSAGES,
            component: () => import('../views/message/allMessages.vue'),
          },
        ]
      }
    ]
  },
  // 配置中心
  {
    path: dataDir.mainAppRoutes.CONFIG_CENTER,
    name: dataDir.mainAppRouteNames.CONFIG_CENTER,
    component: () => import('../views/configurationCenter/home.vue'),
    children: [
      {
        path: dataDir.mainAppRoutes.CONFIG_CENTER + '/projectAttribute',
        name: 'Project',
        component: () => import('../views/projectAttribute/projectAttribute.vue'),

      }
      //       {
      //         name:'Project',
      //         level:1,
      //         icon:'icon-sv-submitorder',
      //         iconSize:'20',
      //         index:'/projectAttribute',
      //           children: [
      //           {
      //               name: 'Project Attribute',
      //               index: '/projectAttribute',
      //               level: 2
      //           },
      //           ]
      // }
    ]
  },
];

// 路由处理
function handleRoutes(routeTree: any[], layer: number, parent?: any) {
  routeTree.forEach((item: any) => {
    if (!item.meta) {
      item.meta = {};
    }
    item.meta.layer = layer;
    if (!item.meta.parents) {
      item.meta.parents = [];
    }
    if (parent) {
      item.meta.parents.push(parent);
    }
    if (item.children) {
      handleRoutes(item.children, layer + 1, item);
    }
  });
}

// 手动重定向
function autoRedirect() {
  let pathNames = window.location.pathname.split('/');
  let pathPrefix = '/' + (pathNames[1] || '');
  if (mainAppRouteList.indexOf(pathPrefix) === -1 && pathPrefix !== dataDir.HOME_ROUTE) {
    let obj = {
      back: null,
      current: dataDir.HOME_ROUTE,
      forward: null
    }
    window.history.replaceState(obj, '', dataDir.HOME_ROUTE);
  }
}

handleRoutes(routes, 0);
autoRedirect();

// 创建路由
let router = createRouter({
  history: createWebHistory(),
  routes
});

/***
 * @method routerHanderFn 路由处理
 * @param {boolean} isFromMicroApp 是否是来自微应用
 * @param {object} to 跳转目的路由
 * @param {object} from 跳转来源路由(暂时未用到，已在方法声明时删除该参数)
 * @return {void}
 */
let updateTimer: any = null;
const routerHanderFn = (isFromMicroApp: boolean, to: any) => {
  /***
   * 更新面包屑路由信息
   * 跳转子应用路由时，子应用路由跳转后，主应用的路由器会跳转到子应用容器的路由，导致当前路由信息为自用用容器路由信息，所以应该排除子应用的主应用容器路由
   */
  if (to.name !== MICRO_APP_NAME) {
    let curRoute: any = {
      name: to.name,
      fullPath: to.fullPath,
      path: to.path,
      query: to.query,
      params: to.params,
      hash: to.hash,
      meta: to.meta,
      layer: to.meta.layer || 0,
      isFullScreenChild: !!to.meta.isFullScreenChild, // 全屏子页面
      isHalfFullScreenChild: !!to.meta.isHalfFullScreenChild, // 非全屏子页面
      transparentBg: !!to.meta.transparentBg, // 页面背景透明
    };
    let menuStore = useMenuStore();
    menuStore.$patch({
      curRoute
    });
  }

  if (to.path === '/login') {
    adminAuth.logout();
  }
  clearTimeout(updateTimer);
  // 如果当前URL为有两个及以上hash值的错误URL，手动更正
  if (window.location.hash.replace(/[^#]/g, '').length > 1) {
    let obj = window.history.state;
    let hashArrs = window.location.hash.split('#');
    let newHash = hashArrs[hashArrs.length - 1];
    let currentPath = window.location.pathname + '/#' + newHash;
    obj.current = currentPath.replace(/(\/)\/+/g, '$1');
    window.history.replaceState(obj, '', obj.current);
    return;
  }
  // 处理历史记录栈
  updateTimer = setTimeout(() => {
    let obj = window.history.state;
    let oldCurrent = obj.current;
    let currentPath = window.location.pathname + '/' + window.location.hash;
    currentPath = currentPath.replace(/(\/)\/+/g, '$1');
    if (oldCurrent !== currentPath) {
      obj.current = currentPath;
      window.history.replaceState(obj, '', currentPath);
    }
  }, 100);
}

// 路由拦截
router.beforeEach((to: any, from: any, next: any) => {
  // 过滤重复的路由跳转，以避免重新渲染，以避免路由跳转的卡顿问题
  if (to.path !== from.path || to.hash !== from.hash || JSON.stringify(to.query) !== JSON.stringify(from.query) || JSON.stringify(to.params) !== JSON.stringify(from.params) ) {
    next();
    // 处理路由信息
    // routerHanderFn(false, to, from); // 暂时未用到from
    routerHanderFn(false, to);
  }
});

export const routerHander: any = routerHanderFn;

export function useRouter() {
  return router;
}

export default router;
