import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useRequestStore = defineStore('request', () => {
  // token
  const TokenKey = 'IWMS-Token';
  const RefreshTokenKey = 'IWMS-R-Token';
  const ExpireKey = 'IWMS-Token-Expire-Time';
  let token = ref(getToken());
  let refreshToken = ref(getRefreshToken());
  let expireTime = ref(getExpireTime()); // token过期的时间 (这里是刷新token时间，比真实过期的时间略小)
  let tokenDuration = ref(3600); // token的有效期(s)
  let requestTimer: any = null; // 请求操作定时器
  let requestHandler = () => {
    console.log('Default request handler.')
  } // 请求处理器

  // Token
  function getToken() {
    return localStorage.getItem(TokenKey);
  }
  
  function setToken(value: any, refreshToken: string, expiresIn: number) {
    token.value = value;
    localStorage.setItem(TokenKey, value);
    if (window.$setGlobalState) {
      window.$setGlobalState({ token: value });
    }
    setRefreshToken(refreshToken);
    setExpireTime(expiresIn);
  }

  // Expire Time
  function getExpireTime() {
    let loc: any = localStorage.getItem(ExpireKey);
    if (loc) {
      loc = Number(loc);
    }
    return typeof loc === 'number' ? loc : null;
  }

  function setExpireTime(expiresIn: number) { // expiresIn token有效期时间（S）
    tokenDuration.value = expiresIn; // 更新token有效期时间（S）
    let duration = 3000;
    if (typeof expiresIn === 'number') {
      if (expiresIn > 120) {
        duration = expiresIn - 60;
      } else {
        duration = expiresIn;
      }
    }
    if (duration > 7200) {
      duration = 7200;
    }
    let date = new Date().getTime() + duration * 1000;
    localStorage.setItem(ExpireKey, String(date));
    expireTime.value = date; // 更新token刷新的时间点
  }

  // refresh token 
  function getRefreshToken() {
    return localStorage.getItem(RefreshTokenKey);
  }

  function setRefreshToken(value: string) {
    localStorage.setItem(RefreshTokenKey, value);
    refreshToken.value = value;
  }

  // loading
  let isLoading = ref(false);

  // refresh token status
  let isRefreshToken = ref(false);

  // request operation (用于提供一段时间无操作后退出登录功能的接口，request拦截工具使用)

  const registerRequestHandler = (handler: () => void) => {
    requestHandler = handler;
  }

  const updateRequestListener = () => {
    clearTimeout(requestTimer);
    requestTimer = setTimeout(() => {
      requestHandler();
    }, tokenDuration.value * 1000);
  }

  const deleteRequestListener = () => {
    clearTimeout(requestTimer);
  }

  return { 
    token, 
    refreshToken,
    expireTime,
    setToken,
    isLoading,
    isRefreshToken,
    getToken,
    registerRequestHandler,
    updateRequestListener,
    deleteRequestListener,
  };
})