import { defineComponent } from 'vue';
import svgData from './svgData'
export default defineComponent({
    name: 'svSvgIcon',
    props: {
        // 图标名
        type: {
            type: String,
            default: 'reset'
        },
        // 图标颜色
        color: {
            type: String,
            default: '#333333'
        },
        fontSize: {
            type: String,
            default: '16px'
        },
        fontWeight: {
            type: String,
            default: 'normal'
        }
    },
    setup(props: any) {
        return {
            svgData
        }
    },
});