/***
 * v-validate指令的错误信息处理
 * store.errors
 * @property {Object} store.errors 
 * 表单的错误信息对象. 如指令传入{name: 'User Name', validateScope: 'userForm'}, 则访问指定的错误信息对象方式为: store.errors['userForm.User Name'].
 * store.clear
 * @method store.clear 清空当前表单的错误提示信息.
 * @param {String} validateScope 表单分组名
 * store.validateAll 
 * @method store.validateAll 验证指定表单的所有规则.
 * @param {String} validateScope 表单分组名
 * @return {Promise} 返回验证是否通过, 可通过返回值的then方法获取验证结果
 */

import { ref } from 'vue'
import { defineStore } from 'pinia'
import validateRules from '@/utils/validateRules';
import $ from 'jquery'
let filedObj: any = {};
const inputTypes: any = ['email', 'number', 'password', 'search', 'text', 'tel', 'url'];

// 获取已生成的错误提示信息
function getErrorsData() {
    let data: any = {};
    for (let scope in filedObj) {
        let scopeData = filedObj[scope];
        for (let name in scopeData) {
            data[scope + '.' + name] = scopeData[name].errorMessage;
        }
    }
    return data;
}

/***
 * 给输入框dom添加blur事件处理
 * @param {Object} el DOM元素
 * @param {Object} data 输入控件的数据对象
 * @param {Object} errors 用于显示报错信息的数据对象
 */
function addEventHandler(el: any, data: any, errors: any) {
    if (!data.isInput) {
        return;
    }
    let dom = el;
    if (el.tagName.toLowerCase() !== 'input') {
        let doms: any = $(el).find('input');
        if (doms.length) {
            Array.from(doms).forEach((item: any) => {
                let type: string = item.type;
                if (inputTypes.indexOf(type) > -1) {
                    dom = item;
                }
            });
        }
    }
    if (dom.tagName.toLowerCase() !== 'input') {
        return;
    }
    dom.addEventListener('blur', (e: any) => {
        data.value = e.target.value;
        let valRes = doValidate(data);
        if (valRes) { // 输入框组件，当验证通过时，删除报错信息
            data.hasError = false;
            data.errorMessage = '';
        }
        updateErrorsValue(errors);
    });
}

/***
 * 更新errors的值
 */
function updateErrorsValue(errors: any, async?: boolean) {
    if (async) {
        errors.value = {};
        setTimeout(() => {
            errors.value = getErrorsData();
        }, 0);
    } else {
        errors.value = getErrorsData();
    }
}

/***
 * 验证规则
 * @param {Object} data 规则对象数据
 * @return {Boolean} 返回验证是否通过
 */
function doValidate(data: any): boolean {
    // 如果为非必填，并且输入值为空, 则直接验证通过
    if (data.rules.indexOf('required') === -1 && (data.value === '' || data.value === null || data.value === undefined) ) {
        return true; 
    }
    let hasError = false;
    data.rules.forEach((rule: any) => {
        if (hasError || !rule) { // 已有错误规则, 或者没有验证规则( 如空字符串 ),则退出验证
            return;
        }
        let validator: any = null;
        let getMessage: any = null;
        let ruleName = rule;
        let ruleArg = '';
        if (typeof rule === 'string') {
            let arr = rule.split(':');
            ruleName = arr[0];
            if (typeof arr[1] !== 'undefined') {
                ruleArg = arr[1];
            }
        }
        if (rule && typeof rule === 'object' && rule.validator && rule.getMessage) { // 自定义规则
            validator = rule.validator;
            getMessage = rule.getMessage;
        } else if (typeof rule === 'string' && validateRules[ruleName] && validateRules[ruleName].validator) { // 使用已有公共规则
            validator = validateRules[ruleName].validator;
            getMessage = validateRules[ruleName].getMessage;
        } else {
            errorNotice(validator, getMessage);
            return;
        }
        errorNotice(validator, getMessage);
        let result = validator(data.value, data.showName || data.name, ruleArg); // 验证规则
        if (!result) {
            hasError = true;
            data.errorMessage = getMessage(data.value, data.showName || data.name, ruleArg); // 获取错误提示信息
        } else {
            data.errorMessage = '';
        }
    });
    data.hasError = hasError;
    return !hasError;
}

function errorNotice(validator: any, getMessage: any) {
    if (typeof validator !== 'function') {
        console.error("The validator method is required");
    }
    if (typeof getMessage !== 'function') {
        console.error("The getMessage method is required");
    }
}

export const useValidateStore = defineStore('validate', () => {

    // 表单的错误信息对象
    let errors: any = ref({});

    // 清空当前表单的错误提示信息
    let clear = function (validateScope: string) {
        let scopeData = filedObj[validateScope];
        if (scopeData) {
            for (let name in scopeData) {
                scopeData[name].hasError = false;
                scopeData[name].errorMessage = '';
            }
        }
        updateErrorsValue(errors);
    }

    // 验证指定表单的所有规则
    let validateAll = function (validateScope: string | string[]) {
        return new Promise((resove: (validateResult: boolean ) => void ) => {
            setTimeout(()=>{
                let arrs = [];
                if (validateScope instanceof Array) {
                    arrs = validateScope;
                } else {
                    arrs.push(validateScope);
                }
                let passed = true;
                arrs.forEach((scopeName: any) => {
                    let scopeData = filedObj[scopeName];
                    // console.log(scopeData)
                    if (scopeData) {
                        for (let name in scopeData) {
                            let data = scopeData[name];
                            let valRes = doValidate(data);
                            if (!valRes) {
                                passed = false;
                            }
                        }
                    }
                });
                updateErrorsValue(errors);
                resove(passed);
            }, 0);
        });
    }

    let validateAllSync = function (validateScope: string | string[]) {
        let arrs = [];
        if (validateScope instanceof Array) {
            arrs = validateScope;
        } else {
            arrs.push(validateScope);
        }
        let passed = true;
        arrs.forEach((scopeName: any) => {
            let scopeData = filedObj[scopeName];
            if (scopeData) {
                for (let name in scopeData) {
                    let data = scopeData[name];
                    let valRes = doValidate(data);
                    if (!valRes) {
                        passed = false;
                    }
                }
            }
        });
        updateErrorsValue(errors);
        return passed;
    }

    // 添加输入控件数据
    let _addField = function (el: any, data: any) {
        let scope: any = data.validateScope;
        if (!filedObj[scope]) {
            filedObj[scope] = {};
        }
        if (!data.showName) {
            data.showName = data.name;
        }
        data.fieldKey = scope + '.' + data.name;
        data.hasError = false;
        filedObj[scope][data.name] = data;
        updateErrorsValue(errors);
        addEventHandler(el, filedObj[scope][data.name], errors);
    }

    // 更新输入控件的输入值
    let _updateFieldValue = function (scope: string, name: string, value: any) {
        let data: any = filedObj[scope][name];
        if (!filedObj[scope] || !filedObj[scope][name] || data.value === value) {
            return;
        }
        data.value = value;
        let hasError = data.hasError;
        if (hasError && !data.isInput) {
            let valRes: boolean = doValidate(data);
            if (valRes) { // 非输入框组件，当有错误信息并且验证通过时，删除报错信息
                data.hasError = false;
                data.errorMessage = '';
                updateErrorsValue(errors, true);
            }
        }
    }

    // 删除输入控件数据
    let _deleteField = function (scope: string, name: string) {
        if (!filedObj[scope] || !filedObj[scope][name]) {
            return;
        }
        delete filedObj[scope][name];
        if (!Object.keys(filedObj[scope]).length) {
            delete filedObj[scope];
        }
        updateErrorsValue(errors);
    }

    return {
        errors,
        clear,
        validateAll,
        validateAllSync,
        _addField,
        _updateFieldValue,
        _deleteField
    };
})