import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import adminAuth from '@/utils/adminAuth'
import { useAdminInfoStore } from '@/stores/adminInfo';
import { useRequestStore } from '@/stores/request';

export default defineComponent({
    name: 'App',
    setup() {
        const adminInfoStore = useAdminInfoStore();
        let { adminInfo } =  storeToRefs(adminInfoStore);
        const requestStore = useRequestStore();

        let logout = function() {
            adminAuth.logout(true);
        }

        return {
            adminInfo,
            requestStore,
            logout
        };
    }
});