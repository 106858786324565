<template>
  <!-- <div class="sv-nav sv-main-bg-dot2">
    <span @click="logout">Log out</span>
    <span class="admin-name">{{ adminInfo.name }}</span>
  </div> -->
  <span class="sv-page-bg">
    <span class="sv-page-bg-color"></span>
  </span>
  <div class="sv-page">
    <router-view></router-view>
  </div>

  <teleport to="body">
    <div v-show="requestStore.isLoading" class="sv-global-loading">
      <div class="sv-global-load-inner">
        <img src="@/assets/image/loading-white.svg"/>
        <span>Loading...</span>
      </div>
    </div>
  </teleport>
  
</template>

<script lang="ts" src="./App"></script>

<style lang="scss" src="./App.scss"></style>
