import { registerMicroApps, start, initGlobalState, addGlobalUncaughtErrorHandler } from 'qiankun';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import VueVirtualScroller from 'vue-virtual-scroller'
import App from './App.vue'
import router, { routerHander } from './router'
import roleDirective from '@/directives/role'
import validateDirective from '@/directives/validate'
import loadDirective from '@/directives/loading'
import addGlobalProperties from '@/globalProperties'
import adminAuth from '@/utils/adminAuth';
import dataDir from '@/utils/data';
import event from '@/utils/event';
import { useRequestStore } from './stores/request';
import { useMicroAppLoadStore } from './stores/microAppLoad';

// CSS
import 'element-plus/dist/index.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import '@/style/element-plus-reset.scss';
import '@/style/common.scss';

// Create APP
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(ElementPlus);
app.use(VueVirtualScroller);
app.directive('role', roleDirective);
app.directive('validate', validateDirective);
app.directive('loading', loadDirective);

// Global properties
addGlobalProperties(app);

// Custom components
import svVirtualScroll from '@/components/sv-virtual-scroll'
app.component('sv-virtual-scroll', svVirtualScroll);

import svPaging from '@/components/sv-paging'
app.component('sv-paging', svPaging);

import svShowImage from '@/components/sv-show-image'
app.component('sv-show-image', svShowImage);

import svSvgIcon from '@/components/sv-svg-icon'
app.component('sv-svg-icon', svSvgIcon);

// 文本超出移入提示
import svEllipsis from '@/components/sv-ellipsis'
app.component('sv-ellipsis', svEllipsis);

// 字体图标
import svFontIcon from '@/components/sv-font-icon'
app.component('sv-font-icon', svFontIcon);

// 按钮
import svButton from '@/components/sv-button/sv-button.vue';
app.component('sv-button', svButton);

import svSearchBar from '@/components/sv-searchBar/sv-searchBar.vue'
app.component('sv-searchbar', svSearchBar);

import svCustomTableHeader from '@/components/sv-custom-table-header/sv-custom-table-header.vue'
app.component('sv-custom-table-header', svCustomTableHeader);

import svFilterParamsList from '@/components/sv-filter-params-list/index.vue'
app.component('sv-filter-params-list', svFilterParamsList)

import svElTableV2 from '@/components/sv-el-table-v2/sv-el-table-v2.vue'
app.component('sv-el-table-v2', svElTableV2)

app.mount('#main-app');

// 加载状态处理器
const microAppLoader = (loading: boolean) => {
  let store = useMicroAppLoadStore();
  if (loading) {
    store.loadFailed = false;
  }
  store.loading = loading;
}

// 获取主应用数据
const getMicroAppData = () => {
  
  // 请求的服务器地址
  let eNameCardString = '';
  let workOrderString = '';
  let accountString = '';
  let projectString = '';
  if (process.env.NODE_ENV === 'development') {
    eNameCardString = 'http://localhost:9001/';
    workOrderString = 'http://localhost:9000/';
    accountString = 'http://localhost:9002/';
    projectString = 'http://localhost:9005/';
  } else {
    eNameCardString = '/enamecard/';
    workOrderString = '/workorder/';
    accountString = '/auth/';
    projectString = '/projectmanagement/';
  }

  let microApps: any = [
    {
      name: 'workOrder',
      entry: workOrderString,
      container: '#' + dataDir.MICRO_APP_CONTAINER_ID,
      activeRule: dataDir.microAppActiveRoutes.WORK_ORDER_ROUTE,
      loader: microAppLoader
    },
    {
      name: 'enameCard',
      entry: eNameCardString,
      container: '#' + dataDir.MICRO_APP_CONTAINER_ID,
      activeRule: dataDir.microAppActiveRoutes.ENAME_ROUTE,
      loader: microAppLoader
    },
    {
      name: 'account',
      entry: accountString,
      container: '#' + dataDir.MICRO_APP_CONTAINER_ID,
      activeRule: dataDir.microAppActiveRoutes.ACCOUNT_ROUTE,
      loader: microAppLoader
    },
    {
      name: 'project',
      entry: projectString,
      container: '#' + dataDir.MICRO_APP_CONTAINER_ID,
      activeRule: dataDir.microAppActiveRoutes.PROJECT_MAN_ROUTE,
      loader: microAppLoader
    },
  ];

  if (dataDir.MICRO_APP_ROUTE_MODE === 'hash') {
    microApps.forEach((item: any) => {
      item.entry = item.entry + '#/';
    });
  }

  return microApps;
}

// 创建主应用
const startApp = (adminInfo: any) => {

  registerMicroApps(getMicroAppData());

  addGlobalUncaughtErrorHandler((err: any, data: any) => {
    let store = useMicroAppLoadStore();
    if (err?.message?.indexOf("died in status LOADING_SOURCE_CODE: Failed to fetch") > -1) {
      store.loadFailed = true;
    }
    store.loading = false;
  });

  // 启动 qiankun
  start();

  // onGlobalStateChange
  const { setGlobalState } = initGlobalState({
    // 需要暴露给子应用的方法
    methods: {
      adminAuth: adminAuth,
      routerHander: routerHander
    },
    // 事件处理
    event,
    dataDir: dataDir,
    adminInfo: adminInfo,
    token: useRequestStore().getToken(),
    expend: false,
    curRoute: null
  });

  window.$setGlobalState = setGlobalState;

  if (adminInfo && adminInfo.id) {
    setGlobalState({
      adminInfo: adminInfo
    });
  }
}

// To get the logged-in user information
adminAuth.getAdminInfo((success: boolean, adminInfo: any) => {
  startApp(adminInfo);
});
