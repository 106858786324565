<!--/******************************************************************
* @NAME ChenJi
* @CREATEDATE
* @DESC <过滤参数列表>
* ****************************************************************/-->
<template>
  <div id="filter-list">
    <div class="search-params-box" v-if="showSearchData.searchInfo.length > 0 " style="margin-top: 8px;">
      <div class="sv-filter-content">
        <template v-for="(field,fieldIndex) in showSearchData.searchInfo" :key="fieldIndex">
          <span class="sv-tag" style="margin-right: 8px;">
              <span class="tag-text" style="display: flex; flex-wrap: nowrap;"> 
                <span style="min-width:36px; white-space: nowrap;">{{field.title}} : </span>
                <span class="tag-text-search">
                  <span v-if="field.params">
                  <span v-for="item in field.params" :key="item.value">{{item.label}}; </span>
                </span>
                <span v-if="field.type == 'input'">{{field.keyword}}</span>
                <span v-if="field.type == 'cascader'">{{ field.keywordText }} </span>
                <span v-if="field.type == 'select-multiple'">{{returnSelectMultiple(field)}}</span>
                <span v-if="field.type == 'select'">{{returnSelect(field)}}</span>
                <span v-if="field.type == 'select-input' || field.type == 'date-time-group'">{{ field.content[0].keyword + '-' + field.content[1].keyword}}</span>
                </span>
              </span>
              <i class="iconfont icon-sv-icancellation sv-fileter-delete" @click="deleteField(field)" v-if="!isReadOnly"></i>
          </span>
        </template>
        <span class="clear-params" @click="clearAll" style="min-width: 73px;">
          <i class="iconfont icon-sv-cleanup"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps,defineEmits, toRefs} from 'vue';
const emit = defineEmits(['activeSearchBarSearchData','activeSearchBarReset'])
let props = defineProps( ['showSearchData', 'isReadOnly', 'isReset', 'isSearchFace','searchData']);
let {showSearchData,isReadOnly,searchData} = toRefs(props);
let deleteField = function(item){
  let data = searchData.value.searchInfo;
    let i = data.findIndex((child) => {
      return item.title === child.title;
    });
    let field = data[i];
    if (field.params) {
      field.params = [];
    }
    if (field.keyword instanceof Array) {
      field.keyword = [];
    } else {
      field.keyword = '';
    }
    if(field.keyword){
      field.keyword = ''
    }
    if(field.content){
      field.content[0].keyword = '';
      field.content[1].keyword = '';
    }
    if (field.type === 'date-time-group') {
      field.content.forEach(date => {
          date.keyword = '';
      })
    }else if (field.type === 'cascader') {
      field.keyword = [];
    }
    emit('activeSearchBarSearchData');
}
let clearAll = function(){
emit('activeSearchBarReset');
}
let returnSelect = function(field){
let arrString = field.selectData.map((item)=>{
     console.log(field.keyword,(item[field.val]),field.val,field.text,field)
    if( field.keyword === (item[field.val])){
    return item[field.text];
   }
})
return arrString.join(',')

}
let returnSelectMultiple = function(filed){
let arrString = filed.selectData.map((item)=>{
    if( filed.keyword.indexOf(item[filed.val]) != -1){
    return item[filed.text];
   }
}).filter((item)=>{return item})
return arrString.join(',')
}
</script>

<style lang="scss" scoped>
  #filter-list .clear-params {
    margin-left: 7px;
    cursor: pointer;
    & > i{
      font-size: 22px;
      color: #536372;
    }
  }
  .sv-filter-content{
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  #filter-list .sv-tag{
    display: flex;
    overflow: hidden;
  }
  .clear-params i {
    color: var(--main-color);
    vertical-align: middle;
    margin-right: 4px;
    font-size: 16px;
  }

  #filter-list .sv-tag .tag-text{
    margin-right: 4px;
  }
  .sv-tag{
    padding:6px;
    background:#eef3f8;
    box-sizing:border-box;
    font-size:14px;
    border: 1px solid #e6e8eb;
    border-radius: 4px;
    cursor:pointer
  }
  .sv-fileter-delete{
    font-size:9px;
    color: #6c8094;
    margin-left:15px;
    line-height:20px;
  }
  .tag-text-search{
     font-weight:600;
     text-indent: 2px;
  }
</style>
