import { defineComponent, ref, onBeforeMount } from "vue";

export default defineComponent({
  name: "sv-paging",
  emits: ['update:modelValue', 'on-change'],
  props: {
    // 加载状态
    loading: {
      type: Boolean,
      default: false
    },
    // 是否显示选每页展示条数的下拉框
    showPerPage: {
      type: Boolean,
      default: true
    },
    // 分页信息
    modelValue: {
      default() {
        return {
          align: 'right', // 文本对齐方式
          itemCount: 0, // 总记录条数
          perPage: 20, // 每页展示条数
          page: 1, // 当前页数
          pageCount: 1 // 总页数
        };
      }
    }
  },
  setup(props: any, context: any) {

    let perPageList: any = ref([5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 200]);
    let btnText: any = ref({
      firstbtn: 'First',
      prebtn: 'Previous',
      nextbtn: 'Next',
      lastbtn: 'Last',
      gobtn: 'Go',
      first: 'This is the first page',
      pre: 'This is  the first page',
      next: 'This is  the last page',
      last: 'This is  the last page',
      go: 'Please enter a right page',
    });
    // 当前输入框输入的页数
    let pageValue: any = ref(1);
    // 显示提示的类型
    let showMsgType: any = ref('');

    // 选择每页展示条数
    let selectPerPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else {
        data.page = 1;
        context.emit('update:modelValue', data);
        context.emit('on-change', data);
      }
    }

    let msgTimer: any = null;

    // 跳转到首页
    let goToFirstPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else if (data.page !== 1) {
        data.page = 1;
        context.emit('update:modelValue', data);
        context.emit('on-change', data);
      } else {
        showMsgType.value = 'first';
        msgTimer = setTimeout(function () {
          clearTimeout(msgTimer);
          if (showMsgType.value === 'first') {
            showMsgType.value = '';
          }
        }, 1500);
        return false;
      }
    }

    // 跳转到上一页
    let goToPrevPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else if (data.page > 1) {
        data.page = data.page - 1;
        context.emit('update:modelValue', data);
        context.emit('on-change', data);
      } else {
        showMsgType.value = 'pre';
        msgTimer = setTimeout(function () {
          clearTimeout(msgTimer);
          if (showMsgType.value === 'pre') {
            showMsgType.value = '';
          }
        }, 1500);
        return false;
      }
    }

    // 跳转到下一页
    let goToNextPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else if (data.page < data.pageCount) {
        data.page = data.page + 1;
        context.emit('update:modelValue', data);
        context.emit('on-change', data);
      } else {
        showMsgType.value = 'next';
        msgTimer = setTimeout(function () {
          clearTimeout(msgTimer);
          if (showMsgType.value === 'next') {
            showMsgType.value = '';
          }
        }, 1500);
        return false;
      }
    }

    // 跳转到最后一页
    let goToFinallyPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else if (data.page !== data.pageCount) {
        data.page = data.pageCount;
        context.emit('update:modelValue', data);
        context.emit('on-change', data);
      } else {
        showMsgType.value = 'last';
        msgTimer = setTimeout(function () {
          clearTimeout(msgTimer);
          if (showMsgType.value == 'last') {
            showMsgType.value = '';
          }
        }, 1500);
        return false;
      }
    }

    // 输入限制只能输入数字
    let handlePageKeyDown: any = function (e: any) {
      let chars = ['+', '-', 'e', '.'];
      if (chars.indexOf(e.key) > -1) {
        e.preventDefault();
      }
      e.returnValue = /[0-9\bBackspace\b]/.test(e.key);
    }

    // 输入的页数大小限制处理
    let handlePageInput: any = function() {
      let data = props.modelValue;
      let pageCount = data.pageCount;
      if (pageValue.value / 1 > pageCount) {
        if (pageValue.value.length > 1) {
          let len = pageValue.value.length;
          pageValue.value = pageValue.value.slice(0, len - 1);
        } else {
          pageValue.value = pageCount;
        }
      }
    }

    // 跳转到输入的指定页数
    let goToPage: any = function () {
      let data = props.modelValue;
      if (props.loading) {
        return false;
      } else {
        let pageCount = data.pageCount;
        if (pageValue.value && pageValue.value / 1 <= pageCount && pageValue.value / 1 > 0 && /^\d+$/.test(pageValue.value)) {
          data.page = pageValue.value / 1;
          context.emit('update:modelValue', data);
          context.emit('on-change', data);
        } else {
          showMsgType.value = 'goTo';
          msgTimer = setTimeout(function () {
            clearTimeout(msgTimer);
            if (showMsgType.value == 'goTo') {
              showMsgType.value = '';
            }
          }, 1500);
          return false;
        }
      }
    }

    onBeforeMount(()=>{
      clearTimeout(msgTimer);
    });

    return {
      perPageList,
      btnText,
      pageValue,
      showMsgType,
      goToFirstPage,
      goToPrevPage,
      goToNextPage,
      goToFinallyPage,
      handlePageKeyDown,
      handlePageInput,
      goToPage,
      selectPerPage,
    }
  }
});
