const colorUtils = {
    darkenColor: (color: string, ratio: number) : string => { 
        const colorInt = parseInt(color.substring(1), 16);
        const r = (colorInt >> 16) & 255;
        const g = (colorInt >> 8) & 255;
        const b = colorInt & 255;

        const newR = Math.max(Math.round(r * (1 - ratio)), 0);
        const newG = Math.max(Math.round(g * (1 - ratio)), 0);
        const newB = Math.max(Math.round(b * (1 - ratio)), 0);

        const newColorInt = (newR << 16) | (newG << 8) | newB;
        const newColor = "#" + newColorInt.toString(16).padStart(6, '0');
        return newColor;
    },
    getRGB: (color: string) : string => {
        let r = parseInt(color.slice(1, 3), 16),
        g = parseInt(color.slice(3, 5), 16),
        b = parseInt(color.slice(5, 7), 16);
        return `${r}, ${g}, ${b}`;
    },
    lightenColor : (color: string, opacity: number) : string => {
        let r = parseInt(color.slice(1, 3), 16),
        g = parseInt(color.slice(3, 5), 16),
        b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
};
export default colorUtils;