<template>
    <span class="sv-imgage-box" :style="{ 'width': width, 'height': height }">
        <template v-if="base64Data || url">
            <img ref="imageRef" :class="className"
                crossorigin="Anonymous"
                :src="base64Data || url"
                :style="{ 'object-fit': objectFit }" 
                @click="handleShowBigImage" 
                @error="handleImageError" 
                @load="handleImageLoaded"
            />
        </template>
        <template v-else>
            <slot>
                <img class="user-photo" :src="require('./img/default-user.png')"
                    :style="{'object-fit': objectFit }" alt />
            </slot>
        </template>
        <Teleport to="body">
            <div class="sv-image-mask" v-if="previewModal" @scroll="(e) => e.preventDefault()">
                <div class="sv-image-preview-box">
                    <img :src="previewUrl" alt="" />
                </div>
                <sv-svg-icon class="sv-image-preview-close" color="#fff" font-weight="bold" font-size="50px" type="circleClose" @click="previewModal = false"></sv-svg-icon>
            </div>
        </Teleport>
    </span>
</template>

<script lang="ts" src="./sv-show-image">
</script>

<style lang="scss" scoped src="./sv-show-image.scss"></style>