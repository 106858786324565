<template>
  <el-dialog append-to-body class="sv-custom-header-modal" v-model="showModal" title="Table Fields Configuration" width="1030px" :close-on-click-modal="false">
    <div>
      <div class="sv-custom-header-reset">
        <span @click="reset">
          <sv-svg-icon type="reset" fontWeight="bold" font-size="16px" color="var(--main-color)"></sv-svg-icon>
          <span style="padding-left: 8px">Reset Configuration</span>
        </span>
      </div>
      <div class="sv-custom-header-field">
        <span>Set Table Fields</span>
      </div>
      <!--分组显示的可勾选字段-->
      <template v-if="isGroupField">
        <div class="sv-custom-header-group" v-for="(pItem, pKey) in allFields" :key="pKey">
          <span class="sv-custom-header-group-title" @click="openFieldGroup">
            <span>{{ filedsTitleObj[pKey] }}</span>
            <span v-if="groupSelectedNums[pKey] && groupSelectedNums[pKey].selectedNum" class="sv-custom-header-num">({{
              groupSelectedNums[pKey].selectedNum }} selected)</span>
            <span v-else class="sv-custom-header-num">(0 selected)</span>
            <span class="sv-custom-header-arrow">
              <sv-svg-icon type="arrowDown" font-size="16px" color="#393939"></sv-svg-icon>
            </span>
          </span>
          <div class="sv-custom-header-group-item">
            <el-checkbox-group v-model="selectedFields" class="sv-custom-header-check"
              @change="getAllSelectedFiledsNum">
              <el-checkbox v-for="(item, key) in allFields[pKey]" :label="key" :key="key"
                class="sv-custom-header-check-item">
                <span style="padding-left: 6px;">{{ key }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </template>
      <!--不需要分组显示的字段-->
      <template v-else>
        <el-checkbox-group v-model="selectedFields" class="sv-custom-header-check" style="margin-top: 0;">
          <el-checkbox v-for="(item, key) in allFields" :label="key" :key="key"
            class="sv-custom-header-check-item">
            <span style="padding-left: 6px;">{{ item.title }}</span>
          </el-checkbox>
        </el-checkbox-group>
      </template>
      <div class="sv-custom-header-field">
        <span style="padding-right: 8px;">Set Display Sequence</span>
        <el-tooltip content="Drag to change the display order of the fields.">
          <sv-svg-icon class="sv-custom-header-warn" type="warning" fontWeight="bold" font-size="16px" color="var(--main-color)"></sv-svg-icon>
        </el-tooltip>
      </div>
      <div class="sv-custom-header-drag" style="padding-top: 0;">
        <draggable v-model="selectedFields" item-key="id">
          <template #item="{ element }">
            <span class="sv-custom-header-tag" v-show="element !== 'Selection' && element !== 'Operation'">
              <span>{{ element }}</span>
              <sv-svg-icon class="sv-custom-header-tag-delete" type="close" fontWeight="bold" font-size="14px"
                color="#fff" @click="deleteTableField(element)"></sv-svg-icon>
            </span>
          </template>
        </draggable>
      </div>
    </div>

    <template #footer>
      <sv-button default right="16" @click="generateTableFields">Confirm</sv-button>
      <sv-button default line grey @click="showModal = false">Cancel</sv-button>
    </template>

  </el-dialog>
</template>

<script lang="tsx" src="./sv-custom-table-header.tsx"></script>

<style scoped lang="scss" src="./sv-custom-table-header.scss"></style>
