<template>
    <RecycleScroller 
        ref="scroller"
        class="sv-vue-scroller" 
        :direction="direction"
        :items="data" 
        :item-size="itemSize" 
        :key-field="keyField" 
        v-slot="{item, index, active}"
        :emitUpdate="true"
        @update="handleUpdate"
        @scroll-start="handleScrollStart"
        @scroll-end="handleScrollEnd"
    >   
        <slot :item="item" :index="index" :active="active">
            <div style="height: 32px">{{ item.name }}</div>
        </slot>
    </RecycleScroller>
</template>
  
<script lang="ts" src="./sv-virtual-scroll.ts">
</script>

<style scoped>
.sv-vue-scroller {
    height: 100%;
}
</style>