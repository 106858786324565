/***
 * v-validate指令
 * directive.value参数说明:
 * @property {String} [validateScope='defaultScope'] 非必填. 表单名,优先级高. 原生input,可不使用该属性,直接使用input的validateScope属性传值. 当没指定该字段时，使用默认值'defaultScope'.
 * @property {String} name 必需的字段.字段的name,优先级高. 原生input,可不使用该属性,直接使用input的name属性传值.
 * @property {String} [showName] 可选字段.字段报错提示信息使用的名字, 优先级高.不传该值默认使用name字段的值作为报错提示标题.
 * @property {any} value 值的响应式属性对象. 原生input可不传该属性，但组件一定要传.
 * @property {Boolean} [isInput=true] 是否是输入框，默认为true. 当不是输入框时需要传值false.
 * @property {Array[String | Object]} rules 
 *  规则列表,优先级高. 从左到右依次验证, 当某条规则验证不通过时停止继续验证右侧的规则. 当存在required规则时，需要把required规则放在最左边.
 *  数组传值类型为字符串：传入的字符串为已在@utils/validateRules配置好的规则的规则名. 规则名字符串可包含参数，如'maxlength:256', 则会使用已声明的'maxlength'规则，并且向其validator和getMessage方法的arg参数传值'256'.
 *  数组传值类型为对象：传入的值为必须包含validator和getMessage方法属性的对象.
 * @property {String | Object} rule 单个规则，当directive.value.rules不存在时才生效.
 * @description
 * 如果控制台警告：Runtime directive used on component with non-element root node. 
 * 说明指令被用在了一个非单根节点的组件上，此时指令不能正常执行，此时可以将指令添加在它的容器上(也需要单根元素容器), 并且该容器需要与该多根节点组件处于同一显示条件下(需要v-if或v-show值改变时，指令同步与验证节点的显示条件一致，以正确增删store的数据 )
 * @author Wangyu
 * 使用示例:
 *      <div class="sv-controls">
 *          <input name="Native Input" v-model="formData.inputValue" v-validate="{ rule: 'required' }"/>
 *          <div class="sv-is-danger" v-show="validateStore.errors['defaultScope.Native Input']">{{ validateStore.errors['defaultScope.Native Input'] }}</div>
 *      </div>
 *      <div class="sv-controls">
 *        <el-input v-model="formData.username" placeholder="Please input" v-validate="{name: 'User Name', value: formData.username, rules: ['required'], validateScope: 'form'}"/>
 *        <div v-show="validateStore.errors['form.User Name']">{{ validateStore.errors['form.User Name'] }}</div>
 *      </div>
 */

import { useValidateStore } from '@/stores/validate'

// 获取表单名
let getValidateScope = function(el: any, data: any) {
    if (data.validateScope) {
        return data.validateScope;
    }
    if (el.attributes.validateScope) {
        return el.attributes.validateScope.value;
    }
    return 'defaultScope';
}

// 获取name
let getName = function(el: any, data: any) {
    if (data.name) {
        return data.name;
    }
    if (el.attributes.name) {
        return el.attributes.name.value;
    }
    return '';
}

// 获取rule
let getRules = function(data: any) {
    if (data.rules instanceof Array) {
        return data.rules;
    }
    if (typeof data.rule === 'string') {
        return [data.rule];
    }
    return [];
}

// 检查name是否正确
let checkName = function(name: string) {
    if (!/^[\w|\s]+$/.test(name)) {
        console.warn("The name '" + name + "' of the control does not conform to the specification, which can cause problems");
    }
}

export default {
    beforeMount(el: any, binding: any) {
        let data = binding.value;
        let value = '';
        if (typeof data.value !== 'undefined') {
            value = data.value;
        } else if (typeof el.value !== 'undefined') {
            value = el.value;
        }
        let obj = {
            validateScope: getValidateScope(el, data),
            name: getName(el, data),
            showName: data.showName,
            rules: getRules(data),
            value: value,
            errorMessage: '',
            hasError: false,
            fieldKey: '',
            isInput: typeof data.isInput === 'boolean' ? data.isInput : true,
        }
        checkName(obj.name);
        checkName(obj.validateScope);
        if (!obj.name || !obj.rules.length) {
            return;
        }
        const validateStore = useValidateStore();
        validateStore._addField(el, obj);
    },
    beforeUpdate(el: any, binding: any) {
        let data = binding.value;
        let dataValue = '';
        if (typeof data.value === 'undefined' && data.isInput) { // 原生input可直接通过value属性获取值
            dataValue = el.value;
        } else if (data.value === binding.oldValue.value) { // value值未改变
            return;
        } else {
            dataValue = data.value;
        }
        let scope = getValidateScope(el, data);
        let name = getName(el, data);
        const validateStore = useValidateStore();
        validateStore._updateFieldValue(scope, name, dataValue);
    },
    beforeUnmount(el: any, binding: any) {
        let data = binding.value;
        let scope = getValidateScope(el, data);
        let name = getName(el, data);
        const validateStore = useValidateStore();
        validateStore._deleteField(scope, name);
    }
}