<template>
    <button type="button" :disabled="disabled" ref="svButton" :class="classObject" class="sv-btn" :style="styleObject">
        <slot></slot>
    </button>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import colorUtils from "@/utils/colorUtils"; 
export default defineComponent({
  name: "sv-button",
  props: {
    //按钮的大小，指的是高度
    size:{
        type: [String, Number],
        default: 40
    },
    color:{
        type: String,
        default: ''
    },
    //距右边内容的距离
    right:{
        type: String,
        default: ''
    },
    //距左边内容的距离
    left:{
        type: String,
        default: ''
    },
    //是否禁用按钮
    disabled:{
        type:Boolean,
        default:false
    },
    //是否是正方形的
    square:{
        type:Boolean,
        default:false
    },
    //是否是默认宽度
    default:{
        type:Boolean,
        default:false
    },
    //是否为线性的
    line:{
        type:Boolean,
        default:false
    },
    //是否是灰色的
    grey:{
        type:Boolean,
        default:false
    },
    //是否是深灰的
    dark:{
        type:Boolean,
        default:false
    },
    //设置圆角圆角
    roundNumber:{
        type:[String, Number],
        default:'',
    },
    //设置宽度
    width:{
        type:[String, Number],
        default:'',
    },
    //是否是虚线
    dashed:{
        type:Boolean,
        default:false
    }
  },
  setup(props: any) {
    const svButton = ref(null);
    
    let classObject = ref({
        //是否是正方形的
        'sv-btn-square': props.square,
        'sv-default-btn': props.default,
        'sv-color-btn': props.color,
        'sv-line-btn': props.line,
        'sv-grey-btn': props.grey,
        'sv-dark-btn': props.dark,
    });
    let styleObject = ref({
        'width': props.square ? props.size + 'px': props.width,
        'height': props.size + 'px',
        'margin-right': props.right + 'px',
        'margin-left': props.left + 'px',
        'border-radius': props.roundNumber ? props.roundNumber + 'px':'',
        'border-style': props.dashed ? 'dashed':'',
    });
    let setColor = () => {
        let dom: any = svButton.value;
        if(props.color){
            //原本颜色
            dom.style.setProperty('--sv-button-color', props.color);
            let darkerColor = colorUtils.darkenColor(props.color, 0.1);
            let deeperColor = colorUtils.darkenColor(props.color,0.2);
            let shallowerColor = colorUtils.lightenColor(props.color,0.1);
            let lightColor = colorUtils.lightenColor(props.color,0.2);
            if(props.line){
                //移入颜色
                dom.style.setProperty('--sv-button-hover-background-color', shallowerColor);
                dom.style.setProperty('--sv-button-hover-border-color', darkerColor);
                //激活的颜色
                dom.style.setProperty('--sv-button-active-background-color', lightColor);
                dom.style.setProperty('--sv-button-active-border-color', deeperColor);
            }else{
                //移入颜色
                dom.style.setProperty('--sv-button-hover-background-color', darkerColor);
                //激活的颜色
                dom.style.setProperty('--sv-button-active-background-color', deeperColor);
            }
        }else{
            let rootStyle = getComputedStyle(document.documentElement);
            let color = rootStyle.getPropertyValue('--main-color').trim();
            let darkerColor = colorUtils.darkenColor(color, 0.1);
            let deeperColor = colorUtils.darkenColor(color, 0.2);
            let shallowerColor = colorUtils.lightenColor(color,0.1);
            let lightColor = colorUtils.lightenColor(color,0.2);
            if(props.line){
                //移入颜色
                dom.style.setProperty('--sv-button-hover-background-color', shallowerColor);
                dom.style.setProperty('--sv-button-hover-border-color', darkerColor);
                //激活的颜色
                dom.style.setProperty('--sv-button-active-background-color', lightColor);
                dom.style.setProperty('--sv-button-active-border-color', deeperColor);
            }else{
                //移入颜色
                dom.style.setProperty('--sv-button-hover-background-color', darkerColor);
                //激活的颜色
                dom.style.setProperty('--sv-button-active-background-color', deeperColor);
            }
            
        }
    };
    onMounted(()=> {
        setColor();
    });
    return {classObject, styleObject, svButton, setColor}
  }
});
</script>

<style lang="scss" src="./sv-button.scss">
</style>