import { defineComponent, ref, onMounted } from 'vue'
import { TableV2FixedDir } from 'element-plus'
import $ from 'jquery'
import draggable from 'vuedraggable'
import svSvgIcon from '../sv-svg-icon'

const filedsTitleData: any = {
  access: "Access Profile",
  accessRight: "Access Right Information",
  accessCodeChange: "Access Code Data Change",
  acInfo: "Access Information",
  areaInfo: "Monitoring Area Information",
  basic: "Basic Information of Report",
  basicDataChange: "Basic Information Data Change",
  captured: "Captured Information",
  cardIssued: "Card Issued Information",
  checkIn: "Check-in Information",
  checkOut: "Check-out Information",
  cost: "Cost Information",
  device: "Device Profile",
  entryInfo: "Entry Information",
  exitInfo: "Exit Information",
  firstEntry: "First Entry",
  incident: "Incident Profile",
  lastAccess: "Last Access",
  lastApperance: "Last Appearance",
  other: "Other Information",
  tempAccess: "Temp Access Data Change",
  user: "User Profile",
  verified: "Verified Information",
  vehicle: "Vehicle Profile",
};

export default defineComponent({
  name: "TableFields",
  components: {
    draggable,
    svSvgIcon
  },
  emits: ['on-change', 'update:modelValue'],
  props: {
    modelValue: {
      type: Array,
      default(){
        return [];
      }
    },
    // 表格的key.需保证唯一
    tableKey: {
      type: String,
      default: "",
    },
    // 所有的表格字段
    allTableFields: {
      type: Object,
      default() {
        return {};
      },
    },
    // 默认选中的表格字段
    defaultTableFields: {
      type: Array,
      default() {
        return [];
      }
    },
    // 字段是否分组
    isGroupField: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, context: any) {

    /***
     * 所有可选字段
     */
    let allFields: any = ref({});
    let filedsTitleObj: any = ref(filedsTitleData); // 字段分组的标题

    // 打开分组信息
    let openFieldGroup = function(e: any) {
      let parent = $(e.target).parents('.sv-custom-header-group');
      parent.find('.sv-custom-header-arrow').toggleClass('open');
      parent.find('.sv-custom-header-group-item').slideToggle();
    }

    // 字段排序
    function fieldSort(data: any) {
      let viewObj: any = {};
      let fields = Object.keys(data);
      fields.sort((a, b) => {
        return a.localeCompare(b);
      });
      fields.forEach(key => {
        viewObj[key] = data[key];
      });
      return viewObj;
    }

    // 获取可选字段
    function getAllFields() {
      let allFieldCopy = JSON.parse(JSON.stringify(props.allTableFields));
      for (let key in allFieldCopy) {
        if (props.isGroupField) {
          for (let field in allFieldCopy[key]) {
            if (allFieldCopy[key][field].setOption === false) {
              delete allFieldCopy[key][field];
            }
          }
          allFieldCopy[key] = fieldSort(allFieldCopy[key]);
        } else {
          if (allFieldCopy[key].setOption === false) {
            delete allFieldCopy[key];
          }
          allFieldCopy = fieldSort(allFieldCopy);
        }
      }
      allFields.value = allFieldCopy;
    }

    /***
     * 已选字段相关
     */
    let selectedFields: any = ref([]); // 已选字段

    function getFieldsStorage(){
      return localStorage.getItem(props.tableKey);
    }

    function setFieldsStorage(){
      localStorage.setItem(props.tableKey, JSON.stringify(selectedFields.value));
    }

    // 获取已选字段
    function getSelectedFields() {
      let storage = getFieldsStorage();
      let selectedFieldsValue = storage ? JSON.parse(storage) : JSON.parse(JSON.stringify( props.defaultTableFields ));
      // 过滤没有的字段
      let allFieldCopy = JSON.parse(JSON.stringify( allFields.value ));
      let isGroupField = props.isGroupField;
      selectedFieldsValue = selectedFieldsValue.filter((key: string) => {
        if (key === 'Selection' || key === 'Operation') {
          return true;
        }
        if (isGroupField) {
          for (let groupKey in allFieldCopy) {
            if (allFieldCopy[groupKey][key]) {
              return true;
            } 
          }
          return false;
        } else if (allFieldCopy[key]) {
          return true;
        } else {
          return false;
        }
      });
      let selectionIndex = selectedFieldsValue.findIndex((item: any) => item === 'Selection');
      if (selectionIndex > -1 && selectionIndex !== 0) {
        let selection = selectedFieldsValue[selectionIndex];
        selectedFieldsValue[selectionIndex] = selectedFieldsValue[0];
        selectedFieldsValue[0] = selection;
      }
      let operationIndex = selectedFieldsValue.findIndex((item: any) => item === 'Operation');
      let max = selectedFieldsValue.length - 1;
      if (operationIndex > -1 && operationIndex !== max) {
        let operation = selectedFieldsValue[operationIndex];
        selectedFieldsValue.splice(operationIndex,1)
        // selectedFieldsValue[operationIndex] = selectedFieldsValue[max];
        selectedFieldsValue.push(operation);
      }
      selectedFields.value = selectedFieldsValue;
    }

    // 删除已选字段
    function deleteTableField(field: string) {
      selectedFields.value = selectedFields.value.filter((item: string) => item !== field);
      getAllSelectedFiledsNum();
    }

    let groupSelectedNums: any = ref({}); // 存放每个分组的所有字段和已选择的每个分组的数量

    // 获取各个分组的已选数据
    function getGroupSelectNumberInfo() {
      let obj: any = {};
      let allFieldCopy = allFields.value;
      for (let key in allFieldCopy) {
        obj[key] = {
          arr: [],
          selectedNum: 0
        };
        for (let childKey in allFieldCopy[key]) {
          obj[key].arr.push(childKey);
        }
      }
      groupSelectedNums.value = obj;
    }

    function getSelectedFiledsNum(obj: any) {
      let num = 0;
      selectedFields.value.forEach((field: string) => {
        if (obj.arr.indexOf(field) > -1) {
          num++;
        }
      })
      obj.selectedNum = num;
    }

    // 获取各个分组的数量
    const getAllSelectedFiledsNum = () => {
      let objInfo: any = JSON.parse(JSON.stringify(groupSelectedNums.value));
      for (let key in objInfo) {
        getSelectedFiledsNum(objInfo[key]);
      }
      groupSelectedNums.value = objInfo;
    }

    /***
     * 生成输出的表格字段
     */
    // 生成Operation
    function generateOperation(hasTitle: boolean) {
      const handleClick = () => {
        openModal();
      }
      return {
        dataKey: 'operation',
        slot: hasTitle ? 'operation' : undefined,
        fixed: TableV2FixedDir.RIGHT,
        width: hasTitle ? 210 : 60,
        type: 'operation',
        headerCellRenderer: () => {
          return (
            <>
              { hasTitle ? <span style="padding-right: 50px">Operation</span> : '' }
              <svSvgIcon style="cursor: pointer" type="tableSet" color="var(--main-color)" font-size="20px" onClick={handleClick}></svSvgIcon>
            </>
          );
        },
      };
    }

    // 生成表格表头字段
    let generateTableFields = function() {
      let titleData: any[] = [];
      let allFieldCopy = allFields.value;
      let hasOperation = false;
      let hasSelection = false;
      selectedFields.value.forEach((field: string) => {
        // 是否有复选框
        if (field === 'Selection') {
          titleData.unshift({ type: 'selection', width: 60, align: 'center' });
          hasSelection = true;
        }
        // 是否有自定义列的操作栏
        else if (field === 'Operation') {
          hasOperation = true;
          titleData.push(generateOperation(true));
        } else {
          // 不属于分组字段
          if (!props.isGroupField) {
            if (allFieldCopy[field]) {
              titleData.push( JSON.parse(JSON.stringify( allFieldCopy[field] )));
            }
          // 属于分组字段
          } else {
            for (let objKey in allFieldCopy) {
              if (allFieldCopy[objKey]) {
                let objInfo: any = allFieldCopy[objKey];
                if (objInfo[field]) {
                  titleData.push( JSON.parse(JSON.stringify( objInfo[field] )) );
                }
              }
            }
          }
        }
      });
      // 添加没有Operation项的自定义字段按钮
      if (!hasOperation) {
        titleData.push(generateOperation(props.defaultTableFields.indexOf('Operation') > -1));
      }
      // 需要下拉项的列表
      if (!hasSelection && props.defaultTableFields.indexOf('Selection') > -1) {
        titleData.unshift({ type: 'selection', width: 60, align: 'center' });
      }
      context.emit('update:modelValue', titleData);
      context.emit('on-change', titleData);
      setFieldsStorage();
      closeModal();
    }

    /***
     * Main
     */
    // 重置
    let reset = function() {
      selectedFields.value = JSON.parse(JSON.stringify(props.defaultTableFields));
      getAllSelectedFiledsNum();
      generateTableFields();
    }

    // 获取上次设置的字段
    function getShowInfo() {
      getAllFields();
      getSelectedFields();
      // 如果是分组显示自定义字段
      if (props.isGroupField) {
        getGroupSelectNumberInfo();
        getAllSelectedFiledsNum();
      }
    }

    let showModal: any = ref(false);

    // 关闭设置弹框
    function closeModal() {
      showModal.value = false;
    }

    // 打开设置弹框
    let openModal = function () {
      getShowInfo();
      showModal.value = true;
    }

    onMounted(() => {
      getShowInfo();
      generateTableFields();
    });

    return {
      allFields,
      filedsTitleObj,
      openFieldGroup,
      selectedFields,
      deleteTableField,
      groupSelectedNums,
      getAllSelectedFiledsNum,
      generateTableFields,
      reset,
      showModal,
      openModal,
    }
  }
});
