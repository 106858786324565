<template>
    <span class="sv-svg-icon" 
        :style="{ 'color': color, 'font-size': fontSize }" 
        :class="{ 'sv-svg-icon-normal': fontWeight === 'normal', 'sv-svg-icon-bold': fontWeight === 'bold' }"
    >
        <svg style="width: 16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" v-html="svgData[type]">
        </svg>
    </span>
</template>

<script lang="ts" src="./sv-svg-icon">
</script>

<style scoped src="./sv-svg-icon.scss" lang="scss"></style>