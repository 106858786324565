// 主应用路由注册
const mainAppRoutes = {
    LOGIN: '/login',
    CONFIG_CENTER: '/configCenter',
    ALL_MESSAGES: '/allMessages'
}
const mainAppRouteNames = {
    APP: 'App',
    LOGIN: 'Login',
    CONFIG_CENTER: 'Configuration Center',
    ALL_MESSAGES: 'All Messages'
};
// 子应用路由模式，目前只能用hash，否则渲染有问题
let MICRO_APP_ROUTE_MODE = 'hash';
// 子应用路由前缀
const HOME_ROUTE = '/ui';
// 子应用dom容器id
const MICRO_APP_CONTAINER_ID = 'sv-micro-app';
// 子应用的路由前缀
let microAppActiveRoutes: any = {
    WORK_ORDER_ROUTE: '/workorder',
    ENAME_ROUTE: '/enamecard',
    PROJECT_MAN_ROUTE: '/projectmanagement',
    ACCOUNT_ROUTE: '/auth'
};
for (let microKey in microAppActiveRoutes) {
    microAppActiveRoutes[microKey] = HOME_ROUTE + microAppActiveRoutes[microKey];
}

// 主应用菜单数据
let menuData = [
    {
        name: 'Project',
        icon: 'icon-sv-project-1',
        iconSize: '20',
        system: microAppActiveRoutes.PROJECT_MAN_ROUTE,
        path: '/project',
        code: 'M_Project'
    },
    {
        name: 'Work Request',
        icon: 'icon-sv-workbench-1',
        iconSize: '20',
        code: 'M_Workbench',
        children: [
            {
                name: 'Dashboard',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/workDashboard',
                code: 'M_WorkDashboard'
            },
            {
                name: 'Workbench',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/workbench',
                code: 'M_WorkbenchPage'
            },
        ]
    },
    {
        name: 'Settings',
        icon: 'icon-sv-setting-1',
        iconSize: '22',
        code: 'M_Setting',
        children: [
            {
                name: 'Work Order',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/workOrder',
                code: 'M_WorkOrderManagement'
            },
            {
                name: 'Project Type',
                system: microAppActiveRoutes.PROJECT_MAN_ROUTE,
                path: '/typeManagement',
                code: 'M_ProjectTypeManagement'
            },
            {
                name: 'Account Management',
                system: microAppActiveRoutes.ACCOUNT_ROUTE,
                path: '/accountTab',
                code: 'M_AccountManagement'
            },
            {
                name: 'Privilege Management',
                system: microAppActiveRoutes.ACCOUNT_ROUTE,
                path: '/privilegeManagement',
                code: 'M_PrivilegeManagement'
            },
            {
                name: 'Managing Agent',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/managingAgent',
                code: 'M_ManagingAgent'
            },
            {
                name: 'Card Template',
                system: microAppActiveRoutes.ENAME_ROUTE,
                path: '/cardTemplate',
                code: 'M_CardTemplate'
            },
            {
                name: 'Location Management',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/locationManagement',
                code: 'M_LocationManagement'
            }
        ]
    }
];

if (window.config?.BillingPolicyModule) {
    menuData.splice(2, 0, {
        name: 'Billing Policy',
        icon: 'icon-sv-billingpolicy',
        iconSize: '20',
        code: 'M_BillingPolicyGroup',
        children: [
            {
                name: 'Billing Policy',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/billingPolicy',
                code: 'M_BillingPolicy'
            },
            {
                name: 'Fee Report',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/feeReport',
                code: 'M_FeeReport'
            },
            {
                name: 'Notification',
                system: microAppActiveRoutes.WORK_ORDER_ROUTE,
                path: '/billingNotification',
                code: 'M_BillingNotification'
            },
        ]
    });
}

// 配置中心菜单数据
let configCenterMenuData = [
    {
        name: 'Project',
        icon: 'icon-sv-submitorder',
        iconSize: '20',
        system: mainAppRoutes.CONFIG_CENTER,
        path: '/projectAttribute',
        children: [
            {
                name: 'Project Attribute',
                path: '/projectAttribute',
                system: mainAppRoutes.CONFIG_CENTER,
            },
        ]
    }
];

// 处理菜单
const handleMenuData = (list: any, level: number) => {
    list.forEach((item: any) => {
        item.level = level;
        if (item.system && item.path && !item.index) {
            item.index = item.system + item.path;
        }
        if (item.children) {
            handleMenuData(item.children, level + 1);
        }
    });
}

// 获取第一个可用路由
const getFirstRoute = (list: any[]): any => {
    let output = null;
    for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item.system && item.index) {
            return item;
        } else if (item.children && item.children.length) {
            output = getFirstRoute(item.children);
        }
    }
    return output;
}

handleMenuData(menuData, 1);
handleMenuData(configCenterMenuData, 1);


export default {
    APP_AUTH_NAME: 'ultimo.iwms.ecard.app.client.v1',
    mainAppRoutes,
    mainAppRouteNames,
    HOME_ROUTE: HOME_ROUTE,
    MICRO_APP_CONTAINER_ID,
    microAppActiveRoutes,
    MICRO_APP_ROUTE_MODE,
    menuData,
    configCenterMenuData,
    getFirstRoute
}