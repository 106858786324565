import { ElMessage } from 'element-plus'

/***
 * @method messageNotice
 * @description 全局弹出提示框, 组件内使用方式 this.messageNotice('success', '').
 * @param {String} type 提示类型，有效值'success'、'error'、'warning'、'normal'.
 * @param {String | Number} message 提示的内容文本,非必要.
 * 当为String类型时，显示对应的文本；
 * 若为Number类型并且type = 'success' 时, 若message为数字，则取以message为键读取messageTemplate对象对应的文本，为此提示文本内容.
 * 当type = 'error' 时，参数为必要的.
 * @param {Object} [options] el-plus 的可选选项，如messageNotice('warning', 'information', { showClose: true }) 则显示关闭按钮.
 */
// 第二个参数message传数字，提示特定的字符串文本
const messageTemplate: any = {
  1: 'Added Successfully!',
  2: 'Operated Successfully!',
  3: 'Deleted Successfully!'
};
const messageNotice = function (type: any, message: any, options?: any) {
  if (!type) {
    type = 'success';
  }
  let str = message;
  if (typeof message === 'number' && messageTemplate[message]) {
    str = messageTemplate[message];
  }
  // 错误提示不存在错误提示信息时，则不显示提示框
  if (type === 'error' && !message) {
    return false;
  }
  let classMap: any = {
    'success': 'sv-el-message-success', // 绿色
    'error': 'sv-el-message-error', // 红色
    'warning': 'sv-el-message-warning', // 橙色
    'info': 'sv-el-message-info', // 蓝色
  };
  let customClass = 'sv-el-message ' + classMap[type];
  ElMessage({
    type: type === 'info' ? 'warning' : type,
    message: str,
    customClass,
    duration: 3000,
    ...options
  });
}

export default messageNotice;
