import request from '@/utils/request'

const LOGIN_ROOT = '/auth';
const AUTH_ROOT = '/auth/api/v1';

/**
 * Login
 */
export function login(data: any, options?: any) {
  return request.post(LOGIN_ROOT + '/connect/token', data, options);
}

export function getAdminInfo(options?: any) {
  return request.get(AUTH_ROOT + '/Accounts/GetLoginAccountInfo?source=Ultimo', options);
}

export function loginOut(data?: any, options?: any) {
  return request.post(LOGIN_ROOT + '/connect/revocation', data, options);
}

export function getRsaPucKey(options?: any) {
  return request.get(AUTH_ROOT + '/Encryption/GetRSAPublicKey', options);
}

//获取验证图片
export function getVerificationCode(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/GetVerificationCode', data, options);
}
//验证图片验证码
export function checkVerificationCode(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/GenerateOTP', data, options);
}
//重置密码
export function resetPassWord(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/ResetPassword', data, options);
}
//验证码位置验证
export function validateCode(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/ValidateCode', data, options);
}
//重置OTP
export function clearVerificationCode(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/ClearOTP', data, options);
}
//验证OTP
export function validateOTP(data: any, options?: any) {
  return request.post(AUTH_ROOT + '/Accounts/ValidateOTP', data, options);
}




